<template>
  <div>
    <b-card>
      <b-card-header class="pb-1">
        <div>
          <b-card-title>{{ $t('CasoviPrakticneObuke') }}</b-card-title>
        </div>
        <b-button-group size="sm">
          <b-button
            :variant="filter === 'zakljucen' ? 'primary' : 'outline-primary'"
            @click="filter = 'zakljucen'"
          >
            {{ $t("Zakljuceni") }}
          </b-button>
          <b-button
            :variant="filter === 'snimljen' ? 'primary' : 'outline-primary'"
            @click="filter = 'snimljen'"
          >
            {{ $t("Nezakljuceni") }}
          </b-button>
          <b-button
            :variant="filter === 'sve' ? 'primary' : 'outline-primary'"
            @click="filter = 'sve'"
          >
            {{ $t("SviCasovi") }}
          </b-button>
        </b-button-group>
      </b-card-header>
      <b-card-body>
        <div>
          <b-table
            v-if="Object.entries(items).length"
            :items="items"
            :fields="fields"
            striped
            responsive
            class="mb-0"
          >
            <template #cell(detalji)="row">
              <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
              <b-form-checkbox
                v-model="row.detailsShowing"
                @change="row.toggleDetails"
              />
            </template>
            <template #cell(id)="data">
              <router-link
                :to="{
                  name: 'autoskola-zasposleni-casovi-izmena',
                  params: { id: data.item.id }
                }"
              >
                <b-button
                  variant="info"
                  size="sm"
                >
                  {{ $t("Izmeni") }}
                </b-button>
              </router-link>
              <b-button
                v-if="data.item.zahtevana_promena"
                v-b-modal.modal-odobri-izmenu
                variant="primary"
                size="sm"
                @click="casZaIzmenu(data.item.id, data.item.napomena_za_izmenu)"
              >
                {{ $t("Odobri") }}
              </b-button>
            </template>
            <template #cell(nocna_voznja)="data">
              <b-badge
                :variant="data.item.nocna_voznja === 1 ? 'dark' : 'warning'"
              >
                {{ data.item.nocna_voznja === 1 ? $t('Nocna') : $t('Dnevna') }}
              </b-badge>
            </template>
            <template #cell(opterecenje)="data">
              <b-badge
                :variant="data.item.opterecenje ? 'primary' : 'secondary'"
              >
                {{ data.item.opterecenje ? $t('PodOpterecenjem') : $t('NijePodOpterecenjem') }}
              </b-badge>
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
              <b-card>
                <b-table-simple
                  small
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th
                        class="col-3"
                      >
                        {{ $t('VremePocetka') }}
                      </b-th>
                      <b-td>{{ row.item.vreme_pocetka }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th
                        class="col-3"
                      >
                        {{ $t('VremeZavrsetka') }}
                      </b-th>
                      <b-td>{{ row.item.vreme_zavrsetka }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th
                        class="col-3"
                      >
                        {{ $t('Dvocas') }}
                      </b-th>
                      <b-td>{{ row.item.dvocas ? $t('Dvocas') : $t('NijeDvocas') }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th
                        class="col-3"
                      >
                        {{ $t('PocetnoStanjeNaPutometru') }}
                      </b-th>
                      <b-td>{{ row.item.pocetno_stanje_na_putometru }}km</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th
                        class="col-3"
                      >
                        {{ $t('KrajnjeStanjeNaPutometru') }}
                      </b-th>
                      <b-td>{{ row.item.krajnje_stanje_na_putometru }}km</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-card>
            </template>

            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>
          </b-table>
          <b-alert
            v-else
            variant="primary"
            show
          >
            <div
              class="alert-body"
              style="padding: 30px;"
            >
              <span>{{ $t('NemaRezultata') }}</span>
            </div>
          </b-alert>
          <b-container
            v-if="items.length > 0"
            fluid="sm"
          >
            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="linkovi.last_page"
              use-router
              align="center"
              class="mt-1"
            />
          </b-container>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-odobri-izmenu"
      ok-only
      ok-variant="danger"
      :ok-title="$t('Posalji')"
      modal-class="modal-danger"
      centered
      :title="$t('OdobriIzmenuZaCas')"
      @ok="potvrdiZahtevanjeIzmene()"
    >
      <b-card-text>
        <h6>{{ $t('DaLiSteSigurniDaZeliteDaOdobriteIzmenuZaOvajCas?') }}</h6>
        <b-container
          v-if="napomenaZaIzmenu"
          class="mt-3"
        >
          <p><strong>{{ $t('KomentarInstruktora') }}</strong></p>
          <p>{{ napomenaZaIzmenu }}</p>
        </b-container>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BFormCheckbox,
  BButton,
  BCardBody,
  BBadge,
  BCard,
  BCardText,
  BContainer,
  BPaginationNav,
  BCardHeader,
  BCardTitle,
  BButtonGroup,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
    BFormCheckbox,
    BCardBody,
    BBadge,
    BCard,
    BCardText,
    BContainer,
    BPaginationNav,
    BCardHeader,
    BCardTitle,
    BButtonGroup,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'detalji', label: '' },
        { key: 'datum', label: this.$i18n.t('Datum') },
        { key: 'ime_kandidata', label: this.$i18n.t('Kandidat') },
        { key: 'vozilo_podaci', label: this.$i18n.t('Vozilo') },
        { key: 'kod_casa', label: this.$i18n.t('KodCasa') },
        { key: 'nocna_voznja', label: this.$i18n.t('NocnaVoznja') },
        { key: 'opterecenje', label: this.$i18n.t('Opterecenje') },
        { key: 'id', label: this.$i18n.t('Akcije') },
      ],
      /* eslint-disable global-require */
      items: [],
      idCasaZaIzmenu: '',
      napomenaZaIzmenu: '',
      linkovi: '',
      filter: 'sve',
    }
  },
  watch: {
    $route() {
      this.pokupiListu()
    },
    // eslint-disable-next-line func-names
    filter: {
      handler() {
        this.$router.push({ path: '/prakticna-obuka', replace: false })
        this.pokupiListu() // call it in the context of your component object
      },
    },
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    pokupiListu() {
      const page = typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1
      this.$http.get(`/autoskole-administratori/casovi-prakticne-obuke?page=${page}&filter=${this.filter}`).then(response => {
        this.items = response.data.casovi
        this.linkovi = response.data.linkovi
      })
    },
    casZaIzmenu(id, napomenaZaIzmenu) {
      this.idCasaZaIzmenu = id
      this.napomenaZaIzmenu = napomenaZaIzmenu
    },
    potvrdiZahtevanjeIzmene() {
      const data = { odobrena_promena: true }
      this.$http.put(`/instruktori/casovi-prakticne-obuke/${this.idCasaZaIzmenu}`, data).then(() => {
        this.pokupiListu()
      })
    },
  },
}
</script>
